const apiVersionMap = {
  // config.default
  'fetchContentful': '/v1b1',
  guestLogin: '/v1b1',
  // useAuthApi
  refreshTrustedLogin: '/v1b1',
  trustedLogin: '/v1b1',
  logout: '/v1b1',
  activateAccount: '/v1b1',
  activateSession: '/v1b1',
  signup: '/v1b1',
  updateUserDetails: '/v1b1',
  forgotPassword: '/v1b1',
  resetPassword: '/v1b1',
  changePassword: '/v1b1',
  addDevice: '/v1b1',
  deleteDevice: '/v1b1',
  registerDevice: '/v1b1',
  userDetails: '/v1b1',

  // useDetailsApi
  getMediaProgress: '/v1b1',
  sendMediaProgress: '/v1b1',
  getNextEpisode: '/v1b1',
  getProducts: '/v1b1',
  getOrderHistory: '/v1b1',
  createOrder: '/v1b1',
  commitOrder: '/v1b1',
  abandonOrder: '/v1b1',
  getProductsDetails: '/v1b1',
  getEpisodeDetails: '/v1b1',
  getMediaInfo: '/v1b1',
  getSeriesDetails: '/v1b1',
  getPlaybackEpisode: '/v1b1',
  getMultipleMediaProgress: '/v1b1',
  getRecentlyWatched: '/v1b1',
  setRecentSearch: '/v1b1',
  getRecentSearch: '/v1b1',
  clearRecentSearch: '/v1b1',

  // useFavApi
  getFavourites: '/v1b1',
  deleteFavourites: '/v1b1',
  addFavourites: '/v1b1',
  isFavouritedItem: '/v1b1',

  // useGeneralApi
  getRailInfo: '/v1b1',
  getSeasonDetails: '/v1b1',
  getChannelInfo: '/v1b1',
  getListItems: '/v1b1',
  getBestMatch: '/v1b1',
  getSearchSuggestion: '/v1b1'
}

export default apiVersionMap
