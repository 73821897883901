import React, { useEffect, useContext } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
/* --------------------------------------------- */
import { version } from '../../config'
/* --------------------------------------------- */
import VERSION from '../../constants/apiVersionMap'
/* --------------------------------------------- */
import { useGlobalNew } from '../../context/global.new.context'
import { GlobalContext } from '../../context/global.context'
import { useMiscsDispatch } from '../../context/miscs.context'
/* --------------------------------------------- */
import useLoggly from '../../containers/useLoggly'
/* --------------------------------------------- */
import { setAutoPlayNextEpisode } from '../../utils/localStorageService'
/* --------------------------------------------- */
import CloseIcon from '../icons/CloseIcon'
import ToggleSwitch from '../ToggleSwitch'
import { DetailsPopup } from '../DetailsSection/style'
/* --------------------------------------------- */
// SettingsPopup component for displaying settings popup and handles events
const SettingsPopup = () => {
    const [state, dispatch] = useGlobalNew()
    const miscsDispatch = useMiscsDispatch()
    /* --------------------------------------------- */
    const { isAutoplay, dispatch: globalDispatch } = useContext(GlobalContext)
    /* --------------------------------------------- */
    const { appMessages } = useSelector(store => store.appConfigReducers)
    const { popupTheme } = useSelector(store => store.themeConfigReducers)
    /* --------------------------------------------- */
    const { log } = useLoggly()
    /* --------------------------------------------- */
    /**
     * It: Closes settings poopup
     */
    const handleCloseClick = () => {
        dispatch({ type: 'CLOSE_SETTINGS_POPUP' })
    }
    /* --------------------------------------------- */
    /**
     * It: Toggles auto play.
     * It: Updates auto play state globally.
     * It: Sends loggly logs.
     */
    const handleAutoPlayChange = (e) => {
        if (e.target.checked) {
            globalDispatch({ type: 'AUTOPLAY_TRUE' })
            setAutoPlayNextEpisode(true)
            log({
                type: 'Debug',
                attr: {
                    module: 'Settings',
                    event: 'autoplay_on'
                }
            })
        }
        else {
            globalDispatch({ type: 'AUTOPLAY_FALSE' })
            setAutoPlayNextEpisode(false)
            log({
                type: 'Debug',
                attr: {
                    module: 'Settings',
                    event: 'autoplay_off'
                }
            })
        }
    }
    /* --------------------------------------------- */
    /**
     * It: Disables body scroll on opening settings popup.
     */
    useEffect(() => {
        if (state.isOpenSettingsPopup) miscsDispatch({ type: 'DISABLE_BODY_SCROLL' })
        else miscsDispatch({ type: 'ENABLE_BODY_SCROLL' })
        return () => {
            miscsDispatch({ type: 'ENABLE_BODY_SCROLL' })
        }
    }, [state.isOpenSettingsPopup, miscsDispatch])
    /* --------------------------------------------- */
    return (
        <div className={`details-page__pop-up settings-popUp ${state.isOpenSettingsPopup ? 'details-page__pop-up---open' : ''}`}>
            <DetailsPopup theme={popupTheme} className='details-page__pop-up-wrapper'>
                <div className='details-page__pop-up-head'>
                    <PopUpHeader {...{ theme: popupTheme }}>{state.settingsTitle || ''}</PopUpHeader>
                    <div className='player-audio__close' onClick={handleCloseClick}>
                        <CloseIcon color={popupTheme && popupTheme.header && popupTheme.header.accent && popupTheme.header.accent.primary} style={{ width: 18, height: 18 }} />
                    </div>
                </div>
                <div className='settings-popUp__content'>
                    <div className='action-single'>
                        <p className='autoplay'>{appMessages.label_settings_autoplay_next_episode}</p>
                        <ToggleSwitch checked={isAutoplay} onChange={handleAutoPlayChange} theme={popupTheme} />
                    </div>
                </div>
                <div className='app-ver-code'>
                <span>{VERSION.VERSION_TEXT} {version.name}</span>
                </div>
            </DetailsPopup>
        </div>
    )
}
/* --------------------------------------------- */
export const PopUpHeader = styled.p`
  color: ${({ theme: { header } }) => header && header.text.primary};
`
/* --------------------------------------------- */
export default SettingsPopup