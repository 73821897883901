import { useCallback, useEffect, useRef, useContext } from 'react'
import { useSelector } from 'react-redux'
import uuidv1 from 'uuid/v1'
/* --------------------------------------------- */
import useGeneralApi from './useGeneralApi'
import useAnalytics from '../containers/useAnalytics'
import useErrorHandler from '../containers/useErrorHandler'
import useLoggly from '../containers/useLoggly'
import { GlobalContext } from '../context/global.context'
import { getUserAuthInfo, setUserAuthInfo, removeLocalStorageData, setSelectedProfile } from '../utils/localStorageService'
import { removeSessionStorageData } from '../utils/sessionStorageService'
import { getFormattedTokenInBase64 } from '../utils/APIUtilFunctions'
/* --------------------------------------------- */
import VERSION from '../constants/apiVersionMap'
/* --------------------------------------------- */
const inProgressApi = {}
const useAuthApi = () => {
  const aLocalRef = useRef()
  const isSharedRef = useRef(false)
  const authConfigRef = useRef(null)
  const { sendAnalytics } = useAnalytics()
  const { handleError } = useErrorHandler()
  const { log } = useLoggly()
  const { isAuthenticated, isShared, userToken, userInfo, dispatch, oktaIktToken } = useContext(GlobalContext)
  /* --------------------------------------------- */
  const { appConfig } = useSelector(store => store.appConfigReducers)
  const { userManagementConfig = {} } = useSelector(store => store.userManagement)
  const { authConfig: userAuthConfig = [] } = userManagementConfig || {}
  /* --------------------------------------------- */
  useEffect(() => {
    const authConfig = userAuthConfig.filter((config) => config.identityProvider === 'vdt')
    if (authConfig.length > 0) {
      authConfigRef.current = authConfig[0]
    }
  }, [
    userAuthConfig
  ])
  /* --------------------------------------------- */
  const userInfoRef = useRef(userInfo)
  const userTokenRef = useRef(userToken)
  const isAuthenticatedRef = useRef(isAuthenticated)
  /* --------------------------------------------- */
  const {
    getData,
    putData,
    postData,
    deleteData
  } = useGeneralApi()
  /* --------------------------------------------- */
  useEffect(() => {
    const authInfoLocal = getUserAuthInfo()
    aLocalRef.current = { ...authInfoLocal, ...userInfo }
  }, [userInfo])
  /* --------------------------------------------- */
  useEffect(() => {
    isSharedRef.current = isShared
  }, [isShared])
  /* --------------------------------------------- */
  useEffect(() => {
    userInfoRef.current = userInfo
  }, [userInfo])
  /* --------------------------------------------- */
  useEffect(() => {
    userTokenRef.current = userToken
  }, [userToken])
  /* --------------------------------------------- */
  useEffect(() => {
    isAuthenticatedRef.current = isAuthenticated
  }, [isAuthenticated])
  /* --------------------------------------------- */
  const getUserDetails = useCallback((baseUrl) => {
    const Authorization = userTokenRef.current
    const url = `${baseUrl}${VERSION.userDetails}/accounts`
    return getData(url, {}, { Authorization })
      .then((res = {}) => {
        const { subscription = {} } = res
        const { subscriptionType = null } = subscription
        return {
          ...res,
          ...subscription,
          subscriptionType: subscriptionType ? subscriptionType.toLowerCase() : null
        }
      })
  }, [getData])
  /* --------------------------------------------- */
  const refreshTrustedLogin = useCallback((forceRefresh) => {
    const baseUrl = appConfig.baseUrl
    if (!baseUrl) return Promise.reject()
    const authConfig = authConfigRef.current || {}
    const accessTokenRefreshInterval = (authConfig.accessTokenRefreshInterval || 0) * 1000
    let authInfoLocal = getUserAuthInfo()
    const { loginSuccessTime, identityProvider, logoutExtendPeriod, refreshInterval, duration } = authInfoLocal
    authInfoLocal = { loginSuccessTime, identityProvider, logoutExtendPeriod, refreshInterval, duration }
    const now = Date.now()
    let nextRefreshTime = now + refreshInterval
    const logoutExtendPeriodTime = loginSuccessTime + logoutExtendPeriod
    if (nextRefreshTime > logoutExtendPeriodTime) nextRefreshTime = logoutExtendPeriodTime
    const url = `${baseUrl}${VERSION.refreshTrustedLogin}/accounts/trustedLogin`
    setUserAuthInfo({ identityProvider }, { merge: true })
    return postData(url, {
      forceRefresh,
      platform: 'web',
      identityProvider,
      authMode: 'refreshToken'
    }, {}, {
      withCredentials: true
    }, {
      isHideErrorScreens: true,
      trustedLogin: true
    }).then((res) => {
      const {
        token = '',
        userId = '',
        userName = '',
        ikt = ''
      } = res
      let { duration } = res || {}
      duration = duration * 1000
      const now = Date.now()
      const loginSuccessTime = now
      const refreshInterval = Math.min(duration, accessTokenRefreshInterval)
      let nextRefreshTime = now + refreshInterval
      const logoutExtendPeriodTime = loginSuccessTime + logoutExtendPeriod
      if (nextRefreshTime > logoutExtendPeriodTime) nextRefreshTime = logoutExtendPeriodTime
      if(ikt && ikt !== "") {
        dispatch({ type: 'OKTA_IKT_TOKEN', value: ikt })
      } else {
        dispatch({ type: 'OKTA_IKT_TOKEN', value: null })
      }
      

      // const apiError = new Error('Testing refreshTrustedLogin api failure')
      // apiError.responseBody = { token }
      // throw apiError
      return { ...res, ...authInfoLocal, token, userId, userName, ikt, duration, loginSuccessTime, nextRefreshTime, refreshInterval }
    }).catch((error) => {
      error = error || {}
      const responseBody = error.responseBody || {}
      const token = responseBody.token || userTokenRef.current
      const subscription = responseBody.subscription || {}
      const userId = responseBody.userId || ''
      const ikt = error.ikt || ''
      if(ikt && ikt !== "") {
        dispatch({ type: 'OKTA_IKT_TOKEN', value: ikt })
      } else {
        dispatch({ type: 'OKTA_IKT_TOKEN', value: null })
      }
      if (now > logoutExtendPeriodTime || !token) {
        dispatch({ type: 'USER_INFO', value: {} })
        let errorMessage = ''
        if (now > logoutExtendPeriodTime) {
          errorMessage = 'Time Exceeded.'
        } else if (!token) {
          errorMessage = 'Login: Token Missing.'
        }
        const timeExceededError = new Error(errorMessage)
        timeExceededError.errorCode = 7001
        timeExceededError.errorMessage = errorMessage
        timeExceededError.apiUrl = url
        timeExceededError.errorStatus = error.errorStatus || ''
        timeExceededError.requestBody = error.requestBody || ''
        timeExceededError.responseId = error.responseId || ''
        timeExceededError.responseBody = error.responseBody || ''
        if(responseBody.errorCode === 7005){
          timeExceededError.errorCode = responseBody.errorCode
        }
        if(responseBody.errorCode !== 1207){
        handleError(timeExceededError, {}, {
          error,
          isTokenPresent: !!token,
          authInfoLocal: { ...authInfoLocal, nextRefreshTime }
        })
        }
        return Promise.reject(timeExceededError)
      } else {
        return { ...authInfoLocal, nextRefreshTime, token, subscription, userId }
      }
    }).then((authInfo) => {
      const token = authInfo.token || ''
      const userAuthInfo = {
        duration: authInfo.duration,
        loginSuccessTime: authInfo.loginSuccessTime,
        nextRefreshTime: authInfo.nextRefreshTime,
        identityProvider: authInfo.identityProvider,
        logoutExtendPeriod: authInfo.logoutExtendPeriod,
        refreshInterval: authInfo.refreshInterval,
        subscriptionType: authInfo?.subscription?.subscriptionType || "",
        customerType: authInfo?.subscription?.customerType || "",
      }
      setUserAuthInfo(userAuthInfo)
      dispatch({ type: 'USER_TOKEN', value: token })
      dispatch({ type: 'GUEST_LOGIN_STATUS', value: 'NOT_NEEDED'})
      const uDetails = userDetails(authInfo)
      dispatch({ type: 'USER_INFO', value: uDetails })
      sendAnalytics({ type: '_user.login', attr: uDetails })
      log({ type: 'Debug', attr: { event: '_user.login', authInfoLocal: userAuthInfo } })
      return uDetails
    })
  }, [
    dispatch, log,
    postData, handleError, appConfig.baseUrl, sendAnalytics
  ])
  /* --------------------------------------------- */
  const verifySession = useCallback((baseUrl, callback, options = {}) => {
    const now = Date.now()
    const isShared = isSharedRef.current
    const userInfo = userInfoRef.current
    const loginSuccessTime = userInfo.loginSuccessTime
    const logoutExtendPeriod = userInfo.logoutExtendPeriod
    const nextRefreshTime = userInfo.nextRefreshTime
    const authInfo = userInfo.authInfo || ''
    // const isAuthenticated = isAuthenticatedRef.current
    const Authorization = userTokenRef.current
    if (!isAuthenticated) {
      return callback()
    }

    const isExceededLogoutExtendPeriod = now > (loginSuccessTime + logoutExtendPeriod)
    const isExceededNextRefreshTime = now > nextRefreshTime

    if (!isShared &&
      (userInfo.identityProvider !== 'mpx' || !authInfo) &&
      (isExceededLogoutExtendPeriod || (userInfo.identityProvider !== 'mpx' && !Authorization)) &&
      !options.isSkipSessionError) {
      const tokenMissingError = new Error('Token Missing.')
      tokenMissingError.errorCode = 7001
      handleError(tokenMissingError, {}, {
        baseUrl,
        isShared,
        loginSuccessTime,
        logoutExtendPeriod,
        errorOn: 'verifySession',
        isExceededLogoutExtendPeriod,
        identityProvider: userInfo.identityProvider,
        isSkipSessionError: options.isSkipSessionError,
        Authorization: !Authorization,
        authInfo: !authInfo,
        url: options.url
      })
      return Promise.reject(tokenMissingError)
    }

    if (isShared || !isExceededNextRefreshTime) {
      return callback()
    } else {
      return refreshTrustedLogin(true).then(() => callback())
    }
  }, [refreshTrustedLogin, handleError, isAuthenticated])
  /* --------------------------------------------- */
  const trustedLogin = useCallback((baseUrl, { code, identityProvider, logoutExtendPeriod, accessTokenRefreshInterval, redirectUri }, headers = {}) => {
    logoutExtendPeriod = logoutExtendPeriod * 1000
    accessTokenRefreshInterval = accessTokenRefreshInterval * 1000
    const tokenDuration = (logoutExtendPeriod + accessTokenRefreshInterval) / 1000
    const url = `${baseUrl}${VERSION.trustedLogin}/accounts/trustedLogin`
    let params = {}
    if (identityProvider === 'cognito') {
      params = {
        platform: 'web',
        authMode: 'authCode',
        identityProvider: 'cognito'
      }
    } else {
      params = {
        code,
        tokenDuration,
        platform: 'web',
        identityProvider,
        authMode: 'authCode',
        redirectUrl: redirectUri
      }
    }
    setUserAuthInfo({ identityProvider })
    return postData(url, params, headers, {
      withCredentials: true
    }, {
      isInitialTrustedLogin: true,
      trustedLogin: true
    }).then((res) => {
      const { token, userId, userName, ikt ="" } = res || {}
      let { duration } = res || {}
      duration = duration * 1000
      const now = Date.now()
      const loginSuccessTime = now
      if(ikt && ikt !== "") {
        dispatch({ type: 'OKTA_IKT_TOKEN', value: ikt })
      } else {
        dispatch({ type: 'OKTA_IKT_TOKEN', value: null })
      }
      const refreshInterval = Math.min(duration, accessTokenRefreshInterval)
      let nextRefreshTime = now + refreshInterval
      const logoutExtendPeriodTime = loginSuccessTime + logoutExtendPeriod
      if (nextRefreshTime > logoutExtendPeriodTime) nextRefreshTime = logoutExtendPeriodTime
      dispatch({type: 'SHOW_SPLASH', value: false})
      return {
        ...res,
        token,
        userId,
        userName,
        ikt,
        duration,
        loginSuccessTime,
        nextRefreshTime,
        identityProvider,
        logoutExtendPeriod,
        refreshInterval
      }
    }).catch((error) => {
        const ikt = error?.ikt || "";
        dispatch({type: 'SHOW_SPLASH', value: false})
        if(ikt && ikt !== "") {
          dispatch({ type: 'OKTA_IKT_TOKEN', value: ikt })
        } else {
          dispatch({ type: 'OKTA_IKT_TOKEN', value: null })
        }
        throw error; // Throw the error again to prevent going to the next then block
    }).then((authInfo) => {
      const token = authInfo?.token || ''
      setUserAuthInfo({
        duration: authInfo?.duration || 0,
        loginSuccessTime: authInfo?.loginSuccessTime || 0,
        nextRefreshTime: authInfo?.nextRefreshTime || 0,
        identityProvider: authInfo?.identityProvider || '',
        logoutExtendPeriod: authInfo?.logoutExtendPeriod || 0,
        refreshInterval: authInfo?.refreshInterval || 0,
        subscriptionType: authInfo?.subscription?.subscriptionType || "",
        customerType: authInfo?.subscription?.customerType || "",
      })
      dispatch({ type: 'GUEST_LOGIN_STATUS', value: 'NOT_NEEDED'})

      dispatch({ type: 'USER_TOKEN', value: token })
      const uDetails = userDetails(authInfo)
      dispatch({ type: 'USER_INFO', value: uDetails })
      sendAnalytics({ type: '_user.login', attr: uDetails })
    })
  }, [
    dispatch,
    postData,
    sendAnalytics
  ])

  /* --------------------------------------------- */
  const updateAccountAPI = useCallback((baseUrl, body, headersParams) => {
    const url = `${baseUrl}/accounts`
    const headers = {}
    const Authorization = getFormattedTokenInBase64(headersParams.userId, headersParams.token)
    if (Authorization) headers.Authorization = Authorization
    return putData(url, body, headers)
  }, [putData])
  /* --------------------------------------------- */
  const getAccountDetails = useCallback((baseUrl, headersParams) => {
    const url = `${baseUrl}/accounts`
    const headers = {}
    const Authorization = getFormattedTokenInBase64(headersParams.userId, headersParams.token)
    if (Authorization) headers.Authorization = Authorization
    return getData(url, {}, headers)
  }, [getData])
  /* --------------------------------------------- */
  const deleteProfileAPI = useCallback((baseUrl, body, urlParams) => {
    const url = `${baseUrl}/accounts/profiles/profile/${urlParams.profileId}?userId=${urlParams.userId}`
    const headers = {}
    const Authorization = getFormattedTokenInBase64(urlParams.userId, urlParams.token)
    if (Authorization) headers.Authorization = Authorization
    return deleteData(url, {}, headers)
  }, [deleteData])
  /* --------------------------------------------- */
  const selectProfileAPI = useCallback((baseUrl, body, urlParams) => {
    const { profileId, userId } = urlParams
    const url = `${baseUrl}/accounts/profiles/profile/${profileId}?userId=${userId}`
    return postData(url, body)
  }, [postData])
  /* --------------------------------------------- */
  const updateProfileAPI = useCallback((baseUrl, body, params) => {
    const url = `${baseUrl}/accounts/profile/${params.profileId}?userId=${params.userId}`
    const headers = {}
    const Authorization = getFormattedTokenInBase64(params.userId, params.token)
    if (Authorization) headers.Authorization = Authorization
    return putData(url, body, headers)
  }, [putData])
  /* --------------------------------------------- */
  const createProfileAPI = useCallback((baseUrl, body, headerParams) => {
    const url = `${baseUrl}/accounts/profile`
    const headers = {}
    const Authorization = getFormattedTokenInBase64(headerParams.userId, headerParams.token)
    if (Authorization) headers.Authorization = Authorization
    return postData(url, body, headers)
  }, [postData])
  /* --------------------------------------------- */
  const mpxLogin = useCallback((baseUrl, { userName, password, identityProvider, authInfo }) => {
    const url = `${baseUrl}/accounts/login`

    const headers = {}
    const Authorization = getFormattedTokenInBase64(userName, password)
    if (Authorization) headers.Authorization = Authorization

    setUserAuthInfo({ identityProvider })

    return postData(url, {}, headers).then((res = {}) => {
      const now = Date.now() / 1000
      let userInfo = {
        loginSuccessTime: now,
        identityProvider,
        ...res
      }
      const profiles = userInfo.profiles || []
      const userId = userInfo.userId || ''
      const authInfo = userInfo.authInfo || ''

      const firstProfile = profiles.filter(({ isAdmin }) => isAdmin)[0]
      const profileId = firstProfile.profileId || ''

      return selectProfileAPI(baseUrl, { authInfo }, { profileId, userId })
        .then((res) => {
          res = res || {}
          const token = res.token || ''
          dispatch({ type: 'GUEST_LOGIN_STATUS', value: 'NOT_NEEDED'})
          dispatch({ type: 'USER_TOKEN', value: token || '' })

          const profile = (res.profile || [])[0] || {}
          let profileType = profile.type === 'Kid' ? 'kids' : 'normal'
          setSelectedProfile(profile)
          dispatch({ type: 'SELECTED_PROFILE', value: profile })

          return getAccountDetails(baseUrl, { userId, token })
            .then((res) => {
              userInfo = { ...userInfo, ...res, token, profileType, identityProvider }
              dispatch({ type: 'USER_INFO', value: userInfo })
              setUserAuthInfo(userInfo)
            })
        })
    })
  }, [dispatch, postData, getAccountDetails, selectProfileAPI])
  /* --------------------------------------------- */
  const login = useCallback((baseUrl, { userName, password, identityProvider, accessTokenRefreshInterval, logoutExtendPeriod, authInfo }) => {
    if (identityProvider === 'cognito') {
      return trustedLogin(baseUrl, {
        authMode: 'authCode',
        identityProvider,
        accessTokenRefreshInterval,
        logoutExtendPeriod
      }, {
        Authorization: getFormattedTokenInBase64(userName, password)
      })
    } else if (identityProvider === 'mpx') {
      return mpxLogin(baseUrl, { userName, password, identityProvider, authInfo })
    }
    return Promise.reject(new Error('Unknown identityProvider'))
  }, [
    trustedLogin, mpxLogin
  ])
  /* --------------------------------------------- */
  const logout = useCallback((baseUrl) => {
    const url = `${baseUrl}${VERSION.logout}/accounts/logout`;
    const oktaToken = oktaIktToken;
    const Authorization = userTokenRef.current ? userTokenRef.current : oktaToken;
    if (!oktaToken && !Authorization) {
      const sessionError = new Error('Session Token Missing')
      return Promise.reject(sessionError)
    }
    const body = {};
    if(oktaToken && oktaToken !== ""){
    body.ikt = oktaToken;
    }
    return postData(url, body, { Authorization }, {
      withCredentials: true
    })
      .then((res) => {
        removeLocalStorageData()
        removeSessionStorageData()
        dispatch({ type: 'USER_INFO', value: {} })
        sendAnalytics({ type: '_user.logout', attr: {
          user_id: userInfo.userId,
          user_email_hash: userInfo.emailHash,
          user_type: userInfo.customerType,
          user_source: userInfo.source
        }})
        dispatch({ type: 'UNAUTHENTICATED' })
        return res;
      })
      .catch((res) => {
        removeLocalStorageData()
        removeSessionStorageData()
        dispatch({ type: 'USER_INFO', value: {} })
        sendAnalytics({ type: '_user.logout', attr: {
          user_id: userInfo.userId,
          user_email_hash: userInfo.emailHash,
          user_type: userInfo.customerType,
          user_source: userInfo.source
        }})
        dispatch({ type: 'UNAUTHENTICATED' })
        return res;
      })
  }, [
    dispatch,
    postData,
    sendAnalytics,
    userInfo,
    oktaIktToken
  ])
  /* --------------------------------------------- */
  const activateAccount = useCallback((baseUrl, { email }) => {
    const url = `${baseUrl}${VERSION.activateAccount}/accounts/email/token`
    return postData(url, { token_type: 'registration', login: email })
  }, [postData])
  /* --------------------------------------------- */
  const activateSession = useCallback((baseUrl, { token }) => {
    const url = `${baseUrl}${VERSION.activateSession}/accounts/activate`
    return postData(url, { token, start_session: true })
  }, [postData])
  /* --------------------------------------------- */
  const signup = useCallback((baseUrl, { email, password, identityProvider, accessTokenRefreshInterval, logoutExtendPeriod }) => {
    let url = `${baseUrl}${VERSION.signup}/accounts`
    if (identityProvider === 'mpx') url = `${baseUrl}${VERSION.signup}/accounts/signUp`
    const body = {
      identityProvider: identityProvider,
      firstName: 'Default',
      lastName: 'User',
      platform: 'web',
      profilePin: 'null',
      details: {
        gender: 'male',
        enablePushNotifications: 'true',
        enablePromotions: 'true',
        acceptedTos: 'true',
        preferredLanguage: 'en',
        email: email,
        avatar: 'https://static.create.diagnal.com/images/mp-avatar/avatar1.png'
      },
      profile: {
        name: 'Default User',
        type: 'adult',
        avatar: 'https://static.create.diagnal.com/images/mp-avatar/avatar1.png',
        enablePushNotifications: 'true',
        preferredLanguage: 'en',
        requireExitPin: ''
      }
    }
    dispatch({ type: 'USER_INFO', value: null })
    dispatch({ type: 'USER_TOKEN', value: null })
    window.store.localStorage.clear()

    return postData(url, body, {
      Authorization: getFormattedTokenInBase64(email, password)
    }).then((authInfo) => {
      // const now = Date.now() / 1000
      return login(baseUrl, {
        userName: email,
        password,
        identityProvider,
        accessTokenRefreshInterval,
        logoutExtendPeriod
      })

      // const now = Date.now() / 1000
      //   const token = authInfo.token || ''
      // setUserAuthInfo({
      //   loginSuccessTime: now,
      //   identityProvider: identityProvider
      // })
      // const userInfoForContext = {
      //   "userId": authInfo.userId,
      //   "userName": authInfo.userName,
      //   "token": authInfo.token,
      //   "authInfo": authInfo.authInfo,
      //   "details": authInfo.details,
      //   "profile": authInfo.profile
      // }
      // dispatch({ type: 'USER_TOKEN', value: token })
      // dispatch({ type: 'USER_INFO', value: userInfoForContext })
    })
  }, [
    login,
    postData,
    dispatch
  ])
  /* --------------------------------------------- */
  const updateUserDetails = useCallback((baseUrl, { firstName, lastName, dob, gender }) => {
    const url = `${baseUrl}${VERSION.updateUserDetails}/accounts`
    return verifySession(baseUrl, () => {
      const Authorization = userTokenRef.current
      return putData(
        url,
        { name: firstName, surname: lastName, details: { birthday: dob, gender } },
        { Authorization }
      )
    }, { url })
  }, [
    putData,
    verifySession
  ])
  /* --------------------------------------------- */

  const forgotPassword = useCallback((baseUrl, { email, identityProvider }) => {
    const url = `${baseUrl}${VERSION.forgotPassword}/accounts/forgotPassword`
    return postData(url, { userName: email, identityProvider })
  }, [postData])
  /* --------------------------------------------- */
  const resetPassword = useCallback((baseUrl, { newPassword, confirmationCode, userName }) => {
    const url = `${baseUrl}${VERSION.resetPassword}/accounts/resetPassword`
    return postData(url, { newPassword, identityProvider: 'cognito', confirmationCode, userName })
  }, [postData])
  /* --------------------------------------------- */
  const changePassword = useCallback((baseUrl, { identityProvider, userName, oldPassword, newPassword }) => {
    const url = `${baseUrl}${VERSION.changePassword}/accounts/password`
    if (identityProvider === 'mpx') return putData(url, { userName: userName, oldPassword, newPassword })
    return putData(url, { userName: userName, oldPassword, newPassword, identityProvider: 'cognito' })
  }, [putData])
  /* --------------------------------------------- */
  const addDevice = useCallback((baseUrl) => {
    const url = `${baseUrl}${VERSION.addDevice}/accounts/devices`
    return verifySession(baseUrl, () => {
      const Authorization = userTokenRef.current
      return getData(url, {}, { Authorization })
    }, { url })
  }, [
    getData,
    verifySession
  ])
  /* --------------------------------------------- */
  const deleteDevice = useCallback((baseUrl, { deviceId }) => {
    const url = `${baseUrl}${VERSION.deleteDevice}/accounts/devices/${deviceId}`
    return verifySession(baseUrl, () => {
      const Authorization = userTokenRef.current
      return deleteData(url, {}, { Authorization })
    }, { url })
  }, [
    deleteData,
    verifySession
  ])
  const guestLogin = ({ username = "" } = {}) => {
    if (inProgressApi.guestLogin) return  inProgressApi.guestLogin; // guestLogin API call already in progress.
    const url = `${appConfig.baseUrl}${VERSION.guestLogin}/accounts/guestLogin`;
    const requestBody = { platform: "web", identityProvider: "guest" }; // TODO: Remove hardcoding
    const userName = username || `guest_web${uuidv1()}`;
    inProgressApi.guestLogin = postData(url, requestBody, {
      Authorization: getFormattedTokenInBase64(userName, "guest"),
    })
      .then((guestLoginResponse) => {
        const token = guestLoginResponse.token || "";
        if (!token) {
          dispatch({ type: "GUEST_LOGIN_STATUS", value: "FAILED" });
          return;
        }
        const identityProvider = guestLoginResponse.identityProvider || "";
        const uDetails = userDetails(guestLoginResponse);
        const userData = {
          ...userInfo,
          ...uDetails,
          token,
          identityProvider,
          guestLoginResponse,
        };
        dispatch({ type: "USER_TOKEN", value: token });
        dispatch({ type: "USER_INFO", value: userData });
        setUserAuthInfo({
          ...userData,
          duration: guestLoginResponse.duration || 0,
          loginSuccessTime: guestLoginResponse.loginSuccessTime || 0,
          nextRefreshTime: guestLoginResponse.nextRefreshTime || 0,
          identityProvider: guestLoginResponse.identityProvider || "",
          logoutExtendPeriod: guestLoginResponse.logoutExtendPeriod || 0,
          refreshInterval: guestLoginResponse.refreshInterval || 0,
          authInfo: guestLoginResponse.authInfo || "",
          userId: guestLoginResponse.userId,
          subscriptionType: guestLoginResponse?.subscription?.subscriptionType || "",
          customerType: guestLoginResponse?.subscription?.customerType || "",
        });
        dispatch({ type: "USER_TOKEN", value: token });
        dispatch({ type: "USER_INFO", value: uDetails }); //
        dispatch({ type: "GUEST_LOGIN_STATUS", value: "SUCCESS" });
        inProgressApi.guestLogin = false;
        return guestLoginResponse;
      })
      .catch(() => {
        dispatch({ type: "GUEST_LOGIN_STATUS", value: "FAILED" });
        inProgressApi.guestLogin = false;
      });

      return  inProgressApi.guestLogin
  };
  /* --------------------------------------------- */
  return {
    login,
    trustedLogin,
    refreshTrustedLogin,
    logout,
    signup,
    activateAccount,
    activateSession,
    verifySession,
    getUserDetails,
    updateUserDetails,
    forgotPassword,
    resetPassword,
    changePassword,
    addDevice,
    deleteDevice,
    selectProfileAPI,
    createProfileAPI,
    deleteProfileAPI,
    updateProfileAPI,
    updateAccountAPI,
    getAccountDetails,
    guestLogin
  }
}
/* --------------------------------------------- */
const userDetails = (res) => {
  const { subscription = {} } = res || {}
  const { subscriptionType = null } = subscription
  return {
    ...res,
    ...subscription,
    subscriptionType: subscriptionType ? subscriptionType.toLowerCase() : null
  }
}
/* --------------------------------------------- */
export default useAuthApi
