import momentTz from 'moment-timezone'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

/** All common utility functions are available here */

function isNull(value) {
  if (value === null || value === undefined || value === '') {
    return true
  }
  for (var i = 1; i < arguments.length; i++) {
    var arg = arguments[i]
    if (arg === null || arg === undefined || arg === '') {
      return true
    }
    if (value[arg] !== null && value[arg] !== undefined && value[arg] !== '') {
      value = value[arg]
    } else {
      return true
    }
  }
  return false
}

function isNotNull() {
  return !isNull.apply(null, arguments)
}

function removePx(value) {
  if (isNotNull(value)) {
    // value = Number(value.toString().split("px")[0]);
    value = parseFloat(value)
    // $(this).css('marginBottom').replace(/[^-\d\.]/g, '');
  }
  return value
}

function getCSSValue(el, styleProp) {
  if (!el) return null
  var camelize = function (str) {
    return str.replace(/-(\w)/g, function (str, letter) {
      return letter.toUpperCase()
    })
  }

  if (el.currentStyle) {
    return el.currentStyle[camelize(styleProp)]
  } else if (document.defaultView && document.defaultView.getComputedStyle) {
    return document.defaultView.getComputedStyle(el, null)
      .getPropertyValue(styleProp)
  } else {
    return el.style[camelize(styleProp)]
  }
}

function isChildElement(obj, parentObj) {
  while (isNotNull(obj, 'tagName') && obj.tagName.toUpperCase() !== 'BODY') {
    if (obj === parentObj) {
      return true
    }
    obj = obj.parentNode
  }
  return false
}

function parseXML(txt) {
  var xmlDoc
  if (window.DOMParser) {
    xmlDoc = new DOMParser().parseFromString(txt, 'text/xml')
  } else { // Internet Explorer
    xmlDoc = new window.ActiveXObject('Microsoft.XMLDOM')
    xmlDoc.async = false
    xmlDoc.loadXML(txt)
  }

  return xmlDoc
}

/* Returns the closest matching image from a list of images and a target width and height*/
const getCloserMatchImage = (width, height, imageArray, isCheckAR = true) => {
  //Image array is empty. Return nothing undefined
  if (!imageArray) return
  // This minimum is used to find the smallest difference between images and target width/height. this is similar to finding the smallest element in an array
  var minimum = 9999999
  //Initialize selected image as null
  var selectedImage = null
  //Loop through all images
  for (var i = 0, image; i < imageArray.length; i++) {
    image = imageArray[i]
    //If aspect ratio check, isCheckAR is true, the aspect ratio of image and the target cell is comapred. a difference of 0.001 is tolerable.
    //If aspect ratio is not correct, loop is continued.
    if (isCheckAR && Math.abs((image.width / image.height) - (width / height)) > 0.001) continue
    //Find the difference between target cell and image dimensions
    var diff = Math.max(Math.abs(image.width - width), Math.abs(image.height - height))
    //If the difference is less than current minimum, the image is set as selected image.
    if (diff < minimum) {
      minimum = diff
      selectedImage = image
    }
  }
  if (!selectedImage) {
    // There is no image available as per the previous logic. returning the first image that's matching either portrait or landscape 
    // based on the expected height & width.
    const expectedMode = width > height ? 'portrait' : 'landscape';
    const allImagesMatchingExpectedMode = imageArray?.filter(i => i.type === expectedMode ) || []
    return allImagesMatchingExpectedMode[0]
  }
  //Return the selected image or null
  return selectedImage
}

function startsWith(str, toMatch) {
  if (!str) return false
  return str.substring(0, toMatch.length) === toMatch
}

function parse(queryString) {
  if (!queryString) return {}
  var query = {}
  var pairs = ''
  if (queryString.indexOf('?') > -1) pairs = queryString.substring(queryString.indexOf('?') + 1).split('&')
  else pairs = queryString.split('&')
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split('=')
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '')
  }
  return query
}

function stringify(obj) {
  var str = []
  for (var p in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, p)) {
      str.push(p + '=' + obj[p])
    }
  }
  return str.join('&')
}

const parseUrl = (function () {
  var a = document.createElement('a')
  return function (url) {
    a.href = url
    let pathname = a.pathname
    if (pathname[0] !== '/') pathname = `/${pathname}` // IE Fix
    return {
      host: a.host,
      hostname: a.hostname,
      pathname,
      port: a.port,
      protocol: a.protocol,
      search: a.search,
      hash: a.hash
    }
  }
})()

const convertUTCtoRelativeTimezone = (utc) => {
  const tz = momentTz.tz.guess()
  return momentTz.utc(utc).tz(tz)
}

const getLocation = (href) => {
  var l = document.createElement('a')
  l.href = href
  return l
}

const isSubscriptionPage = () => {
  const location = decodeURIComponent(window?.location?.pathname);
  const isSubscriptionPage = location === '/subscription'  ;
  return isSubscriptionPage;
}

const toHHMMSS = function (sec) {
  var secNum = parseInt(sec, 10) // don't forget the second param
  var hours = Math.floor(secNum / 3600)
  var minutes = Math.floor((secNum - (hours * 3600)) / 60)
  var seconds = secNum - (hours * 3600) - (minutes * 60)

  if (hours < 10) { hours = '0' + hours }
  if (minutes < 10) { minutes = '0' + minutes }
  if (seconds < 10) { seconds = '0' + seconds }
  return hours + ':' + minutes + ':' + seconds
}

const millisToMinutesAndSeconds = (ms, labels = {}) => {
  const {
    hr: hrL = '',
    min: minL = '',
    sec: secL = ''
  } = labels
  const HHMMSS = toHHMMSS(ms / 1000)
  const timeArr = HHMMSS.split(':')
  const hr = timeArr[0]
  const min = timeArr[1]
  const sec = timeArr[2]
  if (hr === '00') {
    if (min === '00') return `${sec}${secL}`
    else return `${Number(min)}${minL}`
  } else {
    if (min === '00') return `${Number(hr)}${hrL}`
    else return `${Number(hr)}${hrL} ${min}${minL}`
  }
}

const millisToMinutesAndSeconds2 = (ms, labels = {}) => {
  const {
    hr: hrL = '',
    min: minL = '',
    sec: secL = ''
  } = labels
  const HHMMSS = toHHMMSS(ms / 1000)
  const timeArr = HHMMSS.split(':')
  const hr = timeArr[0]
  const min = timeArr[1]
  const sec = timeArr[2]
  if (hr === '00') {
    if (min === '00') return `${sec}${secL}`
    else return `${min}${minL}`
  } else {
    if (min === '00') return `${hr}${hrL}`
    else return `${hr}${hrL} ${min}${minL}`
  }
}

const isValidHex = (hex) => /^#([A-Fa-f0-9]{3,4}){1,2}$/.test(hex)

const getChunksFromString = (st, chunkSize) => st.match(new RegExp(`.{${chunkSize}}`, 'g'))

const convertHexUnitTo256 = (hexStr) => parseInt(hexStr.repeat(2 / hexStr.length), 16)

const getAlphafloat = (a, alpha) => {
  if (typeof a !== 'undefined') { return a / 256 }
  if (typeof alpha !== 'undefined') {
    if (alpha > 1 && alpha <= 100) { return alpha / 100 }
    if (alpha >= 0 && alpha <= 1) { return alpha }
  }
  return 1
}

const hexToRGBA = (hex, alpha) => {
  if (!hex) return null
  if (!isValidHex(hex)) { throw new Error(`Invalid HEX ${hex}`) }
  const chunkSize = Math.floor((hex.length - 1) / 3)
  const hexArr = getChunksFromString(hex.slice(1), chunkSize)
  const [r, g, b, a] = hexArr.map(convertHexUnitTo256)
  return `rgba(${r}, ${g}, ${b}, ${getAlphafloat(a, alpha)})`
}

const prependProtocol = (url) => {
  if (startsWith(url, 'http')) {
    return url
  } else {
    return `${window.location.protocol}${url}`
  }
}

const clearMissingValues = (obj) => {
  const myObj = { ...obj }
  Object.keys(myObj).forEach((key) => (!myObj[key]) && delete myObj[key])
  return myObj
}

const trimLeadingZeroesFromHHMMSS = (inputTime) => {
  for (let i = 0; i < 4; i++) {
    if (inputTime[i] !== ':' && inputTime[i] !== '0') {
      return (inputTime.slice(i))
    }
  }
  return (inputTime.slice(4))
}

const isLocalHost = () => (
  window.location.hostname === 'localhost' ||
  window.location.hostname === '127.0.0.1' ||
  window.location.hostname === ''
)

function toMilliSeconds(hrs, min, sec) {
  return ((hrs * 60 * 60 + min * 60 + sec) * 1000);
}

// Check if body has right padding. This is necessary during enabling/disabling body scrolling on 
// elements with position set as fixed
const hasMargin = () => window.getComputedStyle(document.body)['paddingRight'] !== "0px"

// Enable body scrolling
const enableScroll = (el) => {
  enableBodyScroll(el)
}

// Disable body scrolling
const disableScroll = (el) => {
  disableBodyScroll(el, { reserveScrollBarGap: true })
}
//parse json
const parseJSON = (jsonStr) => {
  try { 
      return JSON.parse(jsonStr) 
    }catch { 
      return {isValidJSON : false, originalString : jsonStr} 
    }

}

//open url in a new tab

const openURLInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}
/* --------------------------------------------- */
//To check whether a url valid or not, if its valid it returns true
const isValidUrl = urlString=> {
  try { 
    return Boolean(new URL(urlString)); 
  }
  catch(e){ 
    return false; 
  }
}
/* --------------------------------------------- */
//To get url extension
const getUrlExtension = ( url ) => {
  if(url && isValidUrl(url)){
    return url.split(/[#?]/)[0].split('.').pop().trim();
  } else {
    return null
  }
}
/* --------------------------------------------- */

var _utils = {
  isNull: isNull,
  isNotNull: isNotNull,
  removePx: removePx,
  getCSSValue: getCSSValue,
  isChildElement: isChildElement,
  parseXML: parseXML,
  getCloserMatchImage,
  startsWith,
  prependProtocol,
  parse,
  stringify,
  convertUTCtoRelativeTimezone,
  getLocation,
  toHHMMSS,
  millisToMinutesAndSeconds,
  millisToMinutesAndSeconds2,
  hexToRGBA,
  parseUrl,
  clearMissingValues,
  trimLeadingZeroesFromHHMMSS,
  isLocalHost,
  toMilliSeconds,
  hasMargin,
  enableScroll,
  disableScroll,
  parseJSON,
  isSubscriptionPage,
  openURLInNewTab,
  getUrlExtension
}

export default _utils
