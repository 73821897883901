import styled from 'styled-components'
import utils from '../../utils/utils'

// Styled component for SearchBar component.
export const StyleWrapper = styled.div`
.suggestion-list {
  background-color: ${({ searchTheme }) => searchTheme?.body?.background?.primary || '#000000'};
}

&.responsiveSearchOpen {
  background-color: ${({ searchTheme }) => searchTheme?.body?.background?.primary || '#000000'};
  
  .suggestion-list {
    background-color: ${({ searchTheme }) => searchTheme?.body?.background?.primary || '#000000'};
  }
  
  .searchbar-res {
    svg {
      #Ellipse_80 path {
        fill: transparent;
      }
      #Tracé_5468 {
        stroke: transparent;
      }
    }
  }
}
  ${({ isFocus, isResponsiveSearchOpen, theme: { normal, selected } }) => {
    let seachIconColor = 'transparent'
    if ((isFocus) && utils.isNotNull(selected, 'image')) {
      seachIconColor = selected.image
    } else if (utils.isNotNull(normal, 'image')) {
      seachIconColor = normal.image
    }
    let _return = `
      svg {
        #Ellipse_80 path{
          fill: ${seachIconColor};
        }
        #Tracé_5468{
          stroke: ${seachIconColor};
        }
      }
    `
    if (!isResponsiveSearchOpen) {
      _return += `
        @media (max-width: 1024px){
          svg {
            #Ellipse_80 path{
              fill: ${utils.isNotNull(normal, 'image') ? normal.image : 'transparent'};
            }
            #Tracé_5468{
              stroke: ${utils.isNotNull(normal, 'image') ? normal.image : 'transparent'};
            }
          }
        }
      `
    }
    return _return
  }}
`

export const SearchInputOuter = styled.div`
  ${({ isFocus, theme: { normal, selected, focussed } }) => {
    if (isFocus) {
      return `
        background-color: ${selected && selected.background};

        & input{
          color: ${selected && selected.text};
          background-color: transparent;
          &::-webkit-input-placeholder {
            color: ${selected && selected.text};
            opacity: 1;
          }
          &:-ms-input-placeholder {
            color: ${selected && selected.text};
            opacity: 1;
          }
          &::placeholder {
            color: ${selected && selected.text};
            opacity: 1;
          }
        }
      `
    } else {
      return `
        background-color: ${normal && normal.background};

        & input{
          color: ${normal && normal.text};
          background-color: transparent;
          &::-webkit-input-placeholder {
            color: ${normal && normal.text};
            opacity: 1;
          }
          &:-ms-input-placeholder {
            color: ${normal && normal.text};
            opacity: 1;
          }
          &::placeholder {
            color: ${normal && normal.text};
            opacity: 1;
          }
        }

        &:hover{
          background-color: ${focussed && focussed.background};

          & input{
            color: ${focussed && focussed.text};
            background-color: transparent;
            &::-webkit-input-placeholder {
              color: ${focussed && focussed.text};
            }
            &:-ms-input-placeholder {
              color: ${focussed && focussed.text};
            }
            &::placeholder {
              color: ${focussed && focussed.text};
            }
          }
        }
      `
    }
  }}
`
/* --------------------------------------------- */
export const ListTitle = styled.p`
    color: ${({ theme: { header } }) => header && header.text.tertiary};
    `
/* --------------------------------------------- */
export const ClearAll = styled.p`
  ${({ theme: { compositeStyle } }) => {
    if (utils.isNotNull(compositeStyle, 'primaryButton', 'normal', 'text')) {
      return `color: ${compositeStyle.primaryButton.normal.text};`
    }
    return 'color: transparent;'
  }};
`
/* --------------------------------------------- */
export const ListItemTitle = styled.span`
    color: ${({ theme: { body } }) => body && body.text.primary};
    &:hover {
      color: ${({ theme: { body } }) => body && body.text.tertiary};
    }
`
/* --------------------------------------------- */
export const ListItemSubtitle = styled.span`
    color: ${({ theme: { body } }) => body && body.text.secondary};
    &::after {
      content: "";
      position: absolute;
      width: 4px;
      height: 4px;
      top: 8px;
      right: 6px;
      border-radius: 2px;
      background: ${({ theme: { body } }) => body && body.text.secondary};
    }
    `
/* --------------------------------------------- */
export const MoreResults = styled.div`
    color: ${({ theme: { footer } }) => footer && footer.text.primary};
`
