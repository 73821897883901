import { getNew, getNewObj } from '../utils/contentfulHandler'
import getFilter from './getFilter'
import getTheme from './getTheme'

// Helper function to form contentful tree
const getPlayerControlConfiguration = (contentful) => {
  const filterLocal = getFilter(contentful)
  const themeLocal = getTheme(contentful)
  const playerControlConfigurationLocal = contentful.playerControlConfiguration
  return playerControlConfigurationLocal.map(({ sys = {}, fields = {} }) => {
    const { id = '', contentType = {} } = sys
    const { sys: contentTypeSys = {} } = contentType
    const { id: contentTypeId = '' } = contentTypeSys
    const {
      filter = {}, theme = [], selectorTheme = [], controllerTheme = []
    } = fields
    return {
      ...fields,
      sysId: id,
      contentTypeId,
      filter: getNewObj(filter, [filterLocal]),
      theme: getNew(theme, themeLocal),
      selectorTheme: getNew(selectorTheme, themeLocal),
      controllerTheme: getNew(controllerTheme, themeLocal)
    }
  })
}

export default getPlayerControlConfiguration
