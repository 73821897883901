import React, { useEffect, useState, useContext } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import SplashDesktop from '../../imgs/splash_web.png'
import SplashMobile from '../../imgs/splash_mobile.png'
/* --------------------------------------------- */
import { GlobalContext } from '../../context/global.context'
/* --------------------------------------------- */
import { device } from '../../constants/breakpoints'
import utils from '../../utils/utils'
import { getGraphics } from '../../utils/localStorageService'
import { version } from '../../config'
/* --------------------------------------------- */
import '../../styles/pages/SplashPage.scss'
import Spinner from '.././Spinner'
import { useGuestLoginController } from '../../apis/useGuestLoginController'
/* --------------------------------------------- */
var VERSION = require('../../constants/hardcoded.json')
/*-----------------------------------------------*/
// SplashPage component having logic for displaying splash page
const SplashPage = () => {
  const { isShowSplash, dispatch, isShowSpinner, guestLoginStatus } = useContext(GlobalContext)
  const [graphics, setGraphics] = useState(getGraphics())
  const { appTheme: theme = {} } = useSelector(store => store.themeConfigReducers)
  /* --------------------------------------------- */
  /**
   * It: Sets image in a state.
   */
  useEffect(() => {
    if (theme.graphics) setGraphics(theme.graphics)
  }, [theme.graphics])
  useGuestLoginController()
  /* --------------------------------------------- */
  /**
   * It: Enable/disable body scroll.
   * It: Show/hide spinner.
   */
  useEffect(() => {
    if (isShowSplash) {
      disableBodyScroll(document.body, { reserveScrollBarGap: true })
      dispatch({ type: "SHOW_SPINNER", value: false })
    }
    return () => {
      enableBodyScroll(document.body)
    }
  }, [isShowSplash, graphics, dispatch])
  /* --------------------------------------------- */
  if (!isShowSplash || ['SUCCESS','FAILED', 'NOT_NEEDED'].includes(guestLoginStatus)) return null
  return (
    <SplashWrapper className='splash-wrapper' theme={theme}>
      <SplashBG className="splash-screen splash-open" graphics={graphics} />
      <div className='app-ver-code'>
        <Spinner size={45} IsLoading={!isShowSpinner} />
        <span className="splash-version">
          {VERSION.VERSION_TEXT} {version.name}
        </span>
       </div>
     </SplashWrapper>
  )
}
/* --------------------------------------------- */
const SplashBG = styled.div`
  ${({ graphics = [] }) => {
    graphics = graphics || []
    return Object.keys(device).map((resolution) => {
      let splashLogoBg = '';
      if (graphics.length === 0) {
        if (resolution.indexOf('mobile') !== -1) {
          splashLogoBg = SplashMobile
        } else {
          splashLogoBg = SplashDesktop
        }
      }
      (graphics || []).map(({ id, images }) => {
        if (id === 'app_splash') {
          if (resolution.indexOf('mobile') !== -1) {
            splashLogoBg = images.map(({ url, orientation }) => {
              if (orientation === 'portrait') {
                return utils.prependProtocol(url)
              }
              return null
            }).filter(Boolean)[0] || ''
          } else {
            splashLogoBg = images.map(({ url, orientation }) => {
              if (orientation === 'landscape') {
                return utils.prependProtocol(url)
              }
              return null
            }).filter(Boolean)[0] || ''
          }
        }
        return null
      })
      return `
        @media ${device[resolution]} { 
          background-image: url('${splashLogoBg}');
          background-size: cover;
        }
      `
    })
  }}
`
/* --------------------------------------------- */

const SplashWrapper = styled.div`
  span.splash-version {
    color: ${({ theme: { footer } }) => footer?.text?.primary};
  }
`;
/* --------------------------------------------- */

SplashPage.propTypes = {
  /** show/hide splash. */
  isShowSplash: PropTypes.bool,
}
/* --------------------------------------------- */
SplashPage.defaultProps = {
  isShowSplash: false
}
/* --------------------------------------------- */
export default SplashPage
/* --------------------------------------------- */
