import React, { useState, useEffect, useRef, useContext } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import CloseIcon from '.././icons/CloseIcon'
import { PopupHeader, PopupContent } from './style'
import utils from '../../utils/utils'
/* --------------------------------------------- */
import { GlobalContext } from '../../context/global.context'
/* --------------------------------------------- */
// Popup component for showing popup
const Popup = (props) => {
  const { closeHandler, title } = props
  const [theme, updateTheme] = useState()
  const { supportPageTheme } = useSelector(store => store.themeConfigReducers)
  /* --------------------------------------------- */
  const contentRef = useRef()
  /* --------------------------------------------- */
  const { dispatch: globalDispatch } = useContext(GlobalContext)
  /* --------------------------------------------- */
  /**
   * It: Updates theme.
   */
  useEffect(() => {
    updateTheme(supportPageTheme)
  }, [supportPageTheme])
  /* --------------------------------------------- */
  /**
   * It: Enable/disable body scroll.
   */
  useEffect(() => {
    let contentEl = contentRef.current
    disableBodyScroll(contentEl, { reserveScrollBarGap: true })
    globalDispatch({ 
      type: 'POPUP_DETAILS', 
      value: {
        inViewport: true,
        useMargin: utils.hasMargin()
      }
    })
    return () => {
      enableBodyScroll(contentEl)
      globalDispatch({ 
        type: 'POPUP_DETAILS', 
        value: {
          inViewport: false,
          useMargin: utils.hasMargin()
        }
      })
    }
  }, [globalDispatch])
  /* --------------------------------------------- */
  return (
    <div className='cms-page'>
      <div className='cms-page__content-wrapper'>
        <div className='cms-page__content'>
          <PopupContent {...{ theme }} className='content'>
            <PopupHeader {...{ theme }} className='cms-page__content-head'>
              <h1 className='heading'>{title}</h1>
              <div className='close-bnt' onClick={closeHandler}>
                <CloseIcon color={theme && theme.header && theme.header.accent?.primary} style={{ width: 20, height: 30 }} />
              </div>
            </PopupHeader>
            <div ref={contentRef} className='cms-page__content-data'>
              {props.children}
            </div>
          </PopupContent>
        </div>
      </div>
    </div>
  )
}
/* --------------------------------------------- */
Popup.propTypes = {
  // component: PropTypes.func,
  CloseHandler: PropTypes.func,
  title: PropTypes.string
}
/* --------------------------------------------- */

Popup.defaultProps = {
  // component: () => { },
  CloseHandler: () => { },
  title: ''
}

export default Popup
