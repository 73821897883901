import { useCallback, useContext, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
/* --------------------------------------------- */
import { GlobalContext } from '../context/global.context'
/* --------------------------------------------- */
import {
  getRecentlyWatchedLocal,
  setRecentlyWatchedLocal,
  getRecentSearchesLocal,
  setRecentSearchesLocal
} from '../utils/sessionStorageService'
/* --------------------------------------------- */
import VERSION from '../constants/apiVersionMap'
/* --------------------------------------------- */
import useAuthApi from './useAuthApi'
import useGeneralApi from './useGeneralApi'
/* --------------------------------------------- */
const useDetailsApi = () => {
  const { verifySession } = useAuthApi()
  const { getData, putData, deleteData, postData } = useGeneralApi()
  const { playerFeature } = useSelector(store => store.featuresReducers)
  const { userInfo, userToken, sessionId } = useContext(GlobalContext)
  const userInfoRef = useRef(userInfo)
  const userTokenRef = useRef(userToken)
  const playerFeatureRef = useRef(playerFeature)
  /* --------------------------------------------- */
  useEffect(() => {
    userInfoRef.current = userInfo
  }, [userInfo])
  /* --------------------------------------------- */
  useEffect(() => {
    userTokenRef.current = userToken
  }, [userToken])
  /* --------------------------------------------- */
  useEffect(() => {
    playerFeatureRef.current = playerFeature
  }, [playerFeature])
  /* --------------------------------------------- */
  const getMediaProgress = useCallback((baseUrl, query) => {
    const url = `${baseUrl}${VERSION.getMediaProgress}/player/progress/${query}`
    return verifySession(baseUrl, () => {
      let header = {}
      const Authorization = userTokenRef.current
      if (Authorization) header = { Authorization }
      return getData(url, {}, header)
    }, { url })
  }, [
    getData, verifySession
  ])
  /* --------------------------------------------- */
  const sendMediaProgress = useCallback((baseUrl, details, enableNotification) => {
    const playerFeature = playerFeatureRef.current
    const maxResumePercent = playerFeature.maxResumePercent || 100
    details.maxResumePercent = maxResumePercent
    const { mediaId, progress, duration, seriesUid, seasonUid, title: titleFromDetails, type, seriesTitle, seasonNumber, episodeNumber} = details
    let url = `${baseUrl}${VERSION.sendMediaProgress}/player/progress?maxResumePercent=${maxResumePercent}`
    if (enableNotification) {
      url += `&enableNotification=${enableNotification}`
    }
    
    let title = titleFromDetails
    if (type === "episode") {
      title =  `${seriesTitle}: season ${seasonNumber}  episode ${episodeNumber}`;
    }
    if (title) url += `&title=${title}`
    updateProgressInDB(details)
    return verifySession(baseUrl, () => {
      const Authorization = userTokenRef.current
      const body = { platform: 'web' }
      if (mediaId) body.uid = mediaId
      if (!isNaN(progress)) body.progress = progress
      if (duration) body.duration = duration
      if (seriesUid) body.seriesUid = seriesUid
      if (seasonUid) body.seasonUid = seasonUid
      return putData(url, body, { Authorization, session_id: sessionId , external_id: userInfo?.userId })
    }, { url })
  }, [putData, sessionId, userInfo.userId, verifySession])
  /* --------------------------------------------- */
  const getNextEpisode = useCallback((baseUrl, { episodeId, seriesId, language }) => {
    const url = `${baseUrl}${VERSION.getNextEpisode}/content/nextPreviousEpisodes?episodeId=${episodeId}&seriesId=${seriesId}&language=${language}`
    return getData(url, {})
  }, [getData])
  /* --------------------------------------------- */
  const getProducts = useCallback((baseUrl, { mediaId = '', categoryId = '', seriesId = '', language }) => {
    const url = `${baseUrl}${VERSION.getProducts}/products?mediaId=${mediaId}&categoryId=${categoryId}&seriesId=${seriesId}&language=${language}`
    const Authorization = userTokenRef.current
    let header = {}
    if (Authorization) header = { Authorization }
    return getData(url, {}, header)
  }, [
    getData
  ])
  /* --------------------------------------------- */
  const getOrderHistory = useCallback((baseUrl) => {
    const url = `${baseUrl}${VERSION.getOrderHistory}/accounts/orders`
    return verifySession(baseUrl, () => {
      const Authorization = userTokenRef.current
      return getData(url, {}, { Authorization })
    }, { url })
  }, [
    getData, verifySession
  ])
  /* --------------------------------------------- */
  const createOrder = useCallback((baseUrl, productId, currency, productIdUrl) => {
    const userInfo = userInfoRef.current
    const userId = userInfo.userId
    const url = `${baseUrl}${VERSION.createOrder}/accounts/createOrder`
    return verifySession(baseUrl, () => {
      const Authorization = userTokenRef.current
      const body = {
        userId,
        storeProductId: productId,
        productId: productIdUrl,
        currency,
        platform: 'web',
        paymentMethod: 'payPal'
      }
      return postData(url, body, { Authorization })
    }, { url })
  }, [
    postData, verifySession
  ])
  /* --------------------------------------------- */
  const getPlaybackEpisode = useCallback((baseUrl, mediaId, { language }) => {
    const url = `${baseUrl}${VERSION.getPlaybackEpisode}/player/playbackEpisode/${mediaId}?language=${language}&maxResumePercent=95`
    return verifySession(baseUrl, () => {
      let header = {}
      const Authorization = userTokenRef.current
      if (Authorization) header = { Authorization }
      return getData(url, {}, header)
    }, { url })
  }, [
    getData, verifySession
  ])
  /* --------------------------------------------- */
  const commitOrder = useCallback((baseUrl, orderId, transactionId, paymentProperties = {}) => {
    const userInfo = userInfoRef.current
    const userId = userInfo.userId
    const url = `${baseUrl}${VERSION.commitOrder}/accounts/commitOrder`
    return verifySession(baseUrl, () => {
      const Authorization = userTokenRef.current
      const body = {
        userId,
        orderId,
        paymentProperties: {
          transactionReceipt: transactionId,
          paymentMethod: 'paypal'
        }
      }
      return postData(url, body, { Authorization })
    }, { url })
  }, [
    postData, verifySession
  ])
  /* --------------------------------------------- */
  const abandonOrder = useCallback((baseUrl, orderId) => {
    const url = `${baseUrl}${VERSION.abandonOrder}/accounts/abandonOrder`
    return verifySession(baseUrl, () => {
      const Authorization = userTokenRef.current
      const body = {
        orderId
      }
      return postData(url, body, { Authorization })
    }, { url })
  }, [
    postData, verifySession
  ])
  /* --------------------------------------------- */
  const getProductsDetails = useCallback((baseUrl, id, { language }) => {
    const url = `${baseUrl}${VERSION.getProductsDetails}/product/${id}?language=${language}`
    return getData(url)
  }, [
    getData
  ])
  /* --------------------------------------------- */
  const getEpisodeDetails = useCallback((baseUrl, query) => {
    const url = `${baseUrl}${VERSION.getEpisodeDetails}/content/items?${query}`
    return getData(url, {})
  }, [
    getData
  ])
  /* --------------------------------------------- */
  const getMediaInfo = useCallback((baseUrl, mediaId, { language }) => {
    const url = `${baseUrl}${VERSION.getMediaInfo}/content/${mediaId}?language=${language}`
    return getData(url, {})
  }, [
    getData
  ])
  /* --------------------------------------------- */
  const getSeriesDetails = useCallback((baseUrl, mediaId, { language }) => {
    const url = `${baseUrl}${VERSION.getSeriesDetails}/getSeriesDetails/${mediaId}?language=${language}`
    return getData(url, {})
  }, [
    getData
  ])
  /* --------------------------------------------- */
  const getChannelPrograms = useCallback((baseUrl, from, to) => {
    const url = `https://feed.entertainment.tv.theplatform.com/f/tzTJhC/dgnlsl30-all-channel-schedules?byLocationId=309639718595&byListingTime=${from}~${to}&lang=en`

    // const url = `https://feed.entertainment.tv.theplatform.com/f/tzTJhC/dgnlsl30-all-channel-schedules?byChannelNumber=7&byListingTime=${fromDateString}~${toDateString}&byLocationId=http%3A%2F%2Fdata.entertainment.tv.theplatform.com%2Fentertainment%2Fdata%2FLocation%2F309639718595&range=-1`
    return getData(url, {})
  }, [
    getData
  ])
  /* --------------------------------------------- */
  const getRecentlyWatched = useCallback((baseUrl, params) => {
    const playerFeature = playerFeatureRef.current
    const maxResumePercent = playerFeature.maxResumePercent || 95
    const url = `${baseUrl}${VERSION.getRecentlyWatched}/accounts/profiles/recently-watched?maxResumePercent=${maxResumePercent}&${params}`
    return verifySession(baseUrl, () => {
      const Authorization = userTokenRef.current
      return getData(url, {}, { Authorization })
    }, { url })
  }, [
    getData,
    verifySession
  ])
  /* --------------------------------------------- */
  const setRecentSearch = useCallback((baseUrl, searchKey, profileId) => {
    const recentSearchesLocal = getRecentSearchesLocal() || []
    updateRecentSearchDB(recentSearchesLocal, searchKey)
    let url = `${baseUrl}${VERSION.setRecentSearch}/recentSearch`
    if (profileId) url += `?profileId=${profileId}`
    return verifySession(baseUrl, () => {
      const Authorization = userTokenRef.current
      return putData(url, { searchKey }, { Authorization })
    }, { url })
  }, [
    putData,
    verifySession
  ])
  /* --------------------------------------------- */
  const getRecentSearch = useCallback((baseUrl, size, profileId) => {
    const url = `${baseUrl}${VERSION.getRecentSearch}/recentSearch?size=${size}&profileId=${profileId}`
    return verifySession(baseUrl, () => {
      const Authorization = userTokenRef.current
      return getData(url, {}, { Authorization })
    }, { url })
  }, [
    getData,
    verifySession
  ])
  /* --------------------------------------------- */
  const clearRecentSearch = useCallback((baseUrl, profileId) => {
    const url = `${baseUrl}${VERSION.clearRecentSearch}/recentSearch?profileId=${profileId}`
    return verifySession(baseUrl, () => {
      const Authorization = userTokenRef.current
      return deleteData(url, {}, { Authorization })
    }, { url })
  }, [
    deleteData,
    verifySession
  ])
  /* --------------------------------------------- */
  const getProgress = useCallback((baseUrl, query) => {
    const url = `${baseUrl}${VERSION.getProgress}/player/progress/${query}`
    return verifySession(baseUrl, () => {
      let header = {}
      const Authorization = userTokenRef.current
      if (Authorization) header = { Authorization }
      return getData(url, {}, header)
    }, { url })
  }, [
    getData, verifySession
  ])
  /* --------------------------------------------- */
  return {
    getNextEpisode,
    setRecentSearch,
    getRecentSearch,
    getSeriesDetails,
    getMediaProgress,
    sendMediaProgress,
    clearRecentSearch,
    getEpisodeDetails,
    getRecentlyWatched,
    getChannelPrograms,
    getMediaInfo,
    getProductsDetails,
    getProducts,
    createOrder,
    getPlaybackEpisode,
    commitOrder,
    getOrderHistory,
    abandonOrder,
    getProgress,
    updateProgressInDB
  }
}
/* --------------------------------------------- */
export const updateProgressInDBIfExists = (details) => {
  const { uid = '', progress = 0, duration = 0 } = details || {}
  const DB = getRecentlyWatchedLocal() || []
  const maxResumePercent = details.maxResumePercent || 100
  const currentPerc = (progress * 100) / duration
  if ((progress < 1 && details.type !== 'episode') || currentPerc >= maxResumePercent) {
    for (let i = 0; i < DB.length; i++) {
      const data = DB[i]
      if (data.uid === uid) {
        DB.splice(i, 1)
        break
      }
    }
  } else {
    for (let i = 0; i < DB.length; i++) {
      const data = DB[i]
      if (data.uid === uid) {
        data.progress = progress
        data.duration = duration
        break
      }
    }
  }
  setRecentlyWatchedLocal(DB)
}
/* --------------------------------------------- */
/**
 * updateProgressInDB does two things:
 * 1. Remove media from DB when progress is 0 or more than equal to 95%.
 * 2. Update progress in DB if it is less than 95%.
 */
export const updateProgressInDB = (details) => {
  let DB = getRecentlyWatchedLocal() || []
  const { uid = '', progress = 0, duration = 0, nextEpisode = null } = details || {}
  if (details.type === 'episode') {
    DB = DB.filter((data) => (data.seriesUid !== details.seriesUid))
  }
  let isFound = false
  const maxResumePercent = details.maxResumePercent || 100
  const currentPerc = (progress * 100) / duration
  if ((progress < 1 && details.type !== 'episode') || currentPerc >= maxResumePercent) {
    isFound = true
    for (let i = 0; i < DB.length; i++) {
      const data = DB[i]
      if (data.uid === uid) {
        DB.splice(i, 1)
        break
      }
    }
    if (details.type === 'episode' && nextEpisode) {
      DB.unshift(nextEpisode)
    }
  } else {
    for (let i = 0; i < DB.length; i++) {
      const data = DB[i]
      if (data.uid === uid) {
        data.progress = progress
        DB.unshift(DB.splice(i, 1)[0])
        isFound = true
        break
      }
    }
  }
  if (!isFound) {
    DB.unshift(details)
  }
  setRecentlyWatchedLocal(DB)
}
/* --------------------------------------------- */
const isExistsInRecentSearch = (DB, searchKey) => {
  const index = DB.indexOf(searchKey)
  const isExist = index > -1
  return [isExist, index]
}
/* --------------------------------------------- */
const updateRecentSearchDB = (DB, searchKey) => {
  const [isAlreadyExist, index] = isExistsInRecentSearch(DB, searchKey)
  if (isAlreadyExist) DB.splice(index, 1)
  DB.unshift(searchKey)
  setRecentSearchesLocal(DB)
}
/* --------------------------------------------- */
export default useDetailsApi
