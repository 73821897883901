
import React from 'react'
import PropTypes from 'prop-types'
/* --------------------------------------------- */
const NextBtnFilledIcon = ({ color, next, ...rest }) => (
  <svg xmlns='http://www.w3.org/2000/svg' {...rest}>
    <g>
      <path id='Clip-2' fill='none' d='M6.3,0.1h16.3v29.5H6.3C6.3,29.5,6.3,0.1,6.3,0.1z' />
      <path
        fill={color} d='M6.7,29.5c0.1,0,0.2,0.1,0.3,0.1c0.2,0,0.3-0.1,0.5-0.2l14.9-13.8c0.1-0.1,0.2-0.3,0.2-0.5c0-0.2-0.1-0.4-0.2-0.5L7.5,0.3C7.3,0.1,7,0,6.7,0.1C6.5,0.2,6.3,0.5,6.3,0.8v28C6.3,29.1,6.5,29.4,6.7,29.5z'
      />
      <path id='Clip-5' fill='none' d='M6.3,29.6h23.3V0H6.3C6.3,0,6.3,29.6,6.3,29.6z' />
      <g id='svg_2'>
        <path id='Fill-4' fill={color} d='M28.2,29.5h1.4V0h-1.4V29.5z' stroke={color} strokeWidth='1.5' />
      </g>
      <text stroke={color} transform='matrix(0.5852863788604736,0,0,0.5852863788604736,1.6782941855490208,21.072635874152184) ' textAnchor='start' fontFamily='Open Sans, Helvetica, Arial, sans-serif' fontSize='24' id='svg_6' y='45.752632' x='4.052632' strokeOpacity='null' strokeWidth='0' fill={color}>{next}</text>
    </g>
  </svg>
)
/* --------------------------------------------- */
NextBtnFilledIcon.propTypes = {
  /** Color of icon. */
  color: PropTypes.string,
  /** Icon css styles. */
  style: PropTypes.object
}
/* --------------------------------------------- */
NextBtnFilledIcon.defaultProps = {
  color: 'transparent',
  style: {
    width: 25,
    height: 25
  }
}
/* --------------------------------------------- */
export default NextBtnFilledIcon
/* --------------------------------------------- */
