import _ from './lodash.custom'
import utils from './utils'
import { filter, isValidOrientation } from '../utils/contentfulHandler'

/** Utility functions to form tree of themes */

export const getThemeData = (data, options) => {
  return {
    header: getTheme(data, 'header'),
    body: getTheme(data, 'body'),
    footer: getTheme(data, 'footer'),
    compositeStyle: getCompositeTheme(data, options),
    graphics: getGraphics(data, options)
  }
}

export const handleTag = (data) => {
  return {
    accent: getColor(data, "accent"),
    background: getColor(data, "background"),
    foreground: getColor(data, "foreground"),
    text: getColor(data, "text"),
    curvedEdges: getField(data, "curvedEdges"),
  };
};

const getTheme = (data, fieldName) => {
  const theme = getField(data, fieldName)
  return {
    accent: getColor(theme, 'accent'),
    background: getColor(theme, 'background'),
    text: getColor(theme, 'text'),
    curvedEdges: getField(theme, 'curvedEdges')
  }
}

const getField = (data, fieldName) => {
  try {
    return data[fieldName]
  } catch (e) {
    return null
  }
}

const getColor = (data, fieldName) => {
  const color = getField(data, fieldName)
  return {
    primary: formatColor(getField(color, 'primary')),
    secondary: formatColor(getField(color, 'secondary')),
    tertiary: formatColor(getField(color, 'tertiary')),
    disabled: formatColor(getField(color, 'disabled')),
    error: formatColor(getField(color, 'error'))
  }
}

const AHexToHexA = (argb) => {
  if (argb && argb.toString().length === 9) {
    const a = argb.slice(1, 3)
    const b = argb.slice(3)
    return (`#${b}${a}`)
  } else return argb
}

const formatColor = (data) => {
  if (!data) return null
  let { code, type, angle, centerX, startColor, centerColor, endColor } = data

  if (code) {
    code = AHexToHexA(code)
    code = utils.hexToRGBA(code)
    return code
  }

  if (!type) return null

  startColor = getField(startColor, 'code')
  startColor = AHexToHexA(startColor)
  startColor = utils.hexToRGBA(startColor)

  centerColor = getField(centerColor, 'code')
  centerColor = AHexToHexA(centerColor)
  centerColor = utils.hexToRGBA(centerColor)

  endColor = getField(endColor, 'code')
  endColor = AHexToHexA(endColor)
  endColor = utils.hexToRGBA(endColor)

  angle = angle - 90
  let cssName = ''
  switch (type) {
    case 'rectangle':
    case 'linear': cssName = 'linear-gradient'; break
    case 'radial': cssName = 'radial-gradient'; break
    default: break
  }
  return `${cssName}(${(type !== 'radial') ? angle + 'deg, ' : ''}
    ${(startColor) ? startColor + ' 0%,' : ''} 
    ${(centerColor) ? centerColor + '' + centerX + ',' : ''} 
    ${(endColor) ? endColor + ' 100%' : ''})`
}

export const getMenuButtonStyle = (theme, options) => {
  const formattedTheme = {}
  let i
  for (i in theme) {
    if (i === 'name') continue
    const compositeTheme = theme[i]
    if (i === 'edgeRadius') formattedTheme[i] = compositeTheme
    else formattedTheme[i] = getMenuButtonColor(compositeTheme, options)
  }
  return formattedTheme
}

const getMenuButtonColor = (theme, options) => {
  return {
    stroke: formatColor(getField(theme, 'stroke')),
    background: formatColor(getField(theme, 'background')),
    text: formatColor(getField(theme, 'text')),
    image: formatColor(getField(theme, 'image')),
    shadow: getField(theme, 'shadow'),
    graphics: getMenuButtonGraphics(getField(theme, 'buttonGraphics'), options)
  }
}

const getMenuButtonGraphics = (data, options = {}) => {
  options = options || {}
  if (!data) return null
  const id = data.id || null
  const anchor = data.anchor || null
  const images = data.images || []
  const orientation = window.innerWidth >= window.innerHeight ? 'landscape' : 'portrait'
  return {
    id,
    anchor,
    images: images.map((props) => {
      props = props || {}

      let url = null
      if (utils.isNotNull(props, 'imageUrl')) url = props.imageUrl
      else if (utils.isNotNull(props, 'media', 'file', 'url')) url = props.media.file.url

      const displayOrientation = (props.filter || {}).displayOrientation || []
      if (url && isValidOrientation(displayOrientation, orientation)) new window.Image().src = url

      return {
        url
      }
    })
  }
}

const getCompositeTheme = (data, options) => {
  const formattedTheme = {}
  const theme = getField(data, 'compositeStyle')
  if (!theme) return formattedTheme
  let i
  for (i in theme
  ) {
    if (i === 'name') continue
    const compositeTheme = theme[i]
    const type = compositeTheme.contentTypeId
    if (type === 'buttonStyle') {
      formattedTheme[i] = {
        disabled: getButtonColor(compositeTheme, 'disabled', options),
        focussed: getButtonColor(compositeTheme, 'focussed', options),
        normal: getButtonColor(compositeTheme, 'normal', options),
        pressed: getButtonColor(compositeTheme, 'pressed', options),
        selected: getButtonColor(compositeTheme, 'selected', options),
        edgeRadius: compositeTheme.edgeRadius
      }
    } else if (type === 'themeSection') {
      formattedTheme[i] = getTheme(theme, i)
    }
  }
  return formattedTheme
}

const getButtonColor = (data, fieldName, options) => {
  const theme = getField(data, fieldName)
  return {
    stroke: formatColor(getField(theme, 'stroke')),
    background: formatColor(getField(theme, 'background')),
    text: formatColor(getField(theme, 'text')),
    image: formatColor(getField(theme, 'image')),
    shadow: getField(theme, 'shadow'),
    graphics: getMenuButtonGraphics(getField(theme, 'buttonGraphics'), options)
  }
}

export const getGraphics = (data, options = {}) => {
  const graphics = getField(data, 'graphics')
  if (!graphics) return []

  const orientation = window.innerWidth >= window.innerHeight ? 'landscape' : 'portrait'

  return graphics.map(({ id, anchor, images = [] }) => {
    images = filter(images || [], '', options || {})
    return {
      id,
      anchor,
      images: images.map((props) => {
        let url = null
        if (utils.isNotNull(props, 'imageUrl')) url = props.imageUrl
        if (utils.isNotNull(props, 'media', 'file', 'url')) url = props.media.file.url
        if (utils.isNotNull(props, 'media', 'file', 'imageUrl')) url = props.media.file.imageUrl

        const displayOrientation = (props.filter || {}).displayOrientation || []
        if (url && isValidOrientation(displayOrientation, orientation)) new window.Image().src = url

        return {
          url, orientation: displayOrientation?.[0] || orientation
        }
      })
    }
  })
}

export const mergeThemes = (first = {}, second) => {
  if (second.compositeStyle) {
    let i
    for (i in second.compositeStyle) {
      if (first.compositeStyle) {
        second.compositeStyle[i] = _.mergeWith(
          {}, (first.compositeStyle[i] || (first.compositeStyle.primaryButton) || {}), second.compositeStyle[i],
          function (a, b) { return b === null ? a : undefined }
        )
      }
    }
  }
  return _.mergeWith({}, first, second, function (a, b) { return b === null ? a : undefined })
}
